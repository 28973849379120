import { ApplicationConfig, DEFAULT_CURRENCY_CODE, LOCALE_ID } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { DialogService } from 'primeng/dynamicdialog';
import { AuthGuard } from './guards/auth.guard';
import { authInterceptor } from './interceptors/auth.interceptor';
import { ConfirmationService } from 'primeng/api';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeEsPe from '@angular/common/locales/es-PE';
registerLocaleData(localeEsPe, 'es-PE');
import { MessageService, PrimeNGConfig } from 'primeng/api';
export const appConfig: ApplicationConfig = {
  providers: [
    MessageService,
    DialogService,
    PrimeNGConfig,
    provideRouter(routes),
    provideClientHydration(),
    provideAnimations(),
    provideHttpClient(withFetch()),
    ConfirmationService,
    AuthGuard,
    { provide: LOCALE_ID, useValue: 'es-PE' }, // Configurar el locale globalmente
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'S/.' }, // Configurar la moneda globalmente
  ],
};
