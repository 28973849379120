<div [class.hidden]="$hiddenSidebar | async" class="sidebar-wrapper" *ngIf="exisToken">
  <div class="sidebar">
    <div class="sidebar-header">
      <button class="close-button" (click)="closeSidebar()">
        <i class="pi pi-times"></i>
      </button>
    </div>
    <div class="sidebar-content">
      <ul>
        <li><a [routerLink]="['/construction']" routerLinkActive="active-link">Sistema de fidelización</a></li>
        <li><a [routerLink]="['/manager/reservation-manager']" routerLinkActive="active-link">Gestión de Reservas</a></li>
        <li><a [routerLink]="['/manager/aparment-manager']" routerLinkActive="active-link">Gestión de Apartamentos</a></li>
        <li><a [routerLink]="['/manager/payment-manager']" routerLinkActive="active-link">Gestor de pagos</a></li>
        <li><a href="/construction" routerLinkActive="active-link">Gestor de Beneficios</a></li>
        <li><a [routerLink]="['/manager/service-manager']" routerLinkActive="active-link">Gestor de servicios</a></li>
        <li><a href="/construction" routerLinkActive="active-link">Gestor de paquetes</a></li>
        <li><a href="/construction" routerLinkActive="active-link">Gestor de promotores</a></li>
      </ul>
    </div>
  </div>
</div>
