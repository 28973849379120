import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { GeneralService } from '../../services/general.service';
;

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit {

 // @Input() sidebarOpen: boolean | undefined = false;
  user: any;
  $hiddenSidebar?: Observable<boolean>;
  exisToken: any;
  tokenStorage: any;

  constructor(
    private loginService: LoginService,
    public generalService: GeneralService
  ) {}

  ngOnInit(): void {

    this.exisToken = this.generalService.get('token');
    this.$hiddenSidebar = this.generalService.getStatusActivitySidebar();
    this.loginService.userSubject.subscribe((res) => {
      this.user = res;
      this.exisToken = this.generalService.get('token');
    });

    this.$hiddenSidebar = this.generalService.getStatusActivitySidebar();

  }

  getTokenStorage() {
    this.tokenStorage = this.generalService.get('token');
  }

  closeSidebar() {
    // if (this.sidebarOpen !== undefined) {
    //   this.sidebarOpen = false;
    // }
  }
}
