import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { LoginService } from '../../services/login.service';
import { GeneralService } from '../../services/general.service';
import { Observable } from 'rxjs';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    SidebarComponent,
    ProgressSpinnerModule,
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent implements OnInit {
  $hiddenSidebar?: Observable<boolean>;
  sidebarOpen = false;
  isMobileScreen = false;
  user: any;
  exisToken: any;
  isDropdownOpen = false;
  isLoading: boolean = false;
  activeRol: string = "Administrador";
  roles: string[] = [];
  constructor(
    private loginService: LoginService,
    public generalService: GeneralService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.exisToken = sessionStorage.getItem('token');
    this.$hiddenSidebar = this.generalService.getStatusActivitySidebar();
    this.loginService.userSubject.subscribe((res) => {
      this.user = res;
      this.exisToken = this.generalService.get('token');
      if (this.exisToken) {
        const decodedToken: any = this.decodeJWT(this.exisToken);
        this.roles = decodedToken.roles.map((role: any) => role.authority);
        this.activeRol = this.roles.includes('ROLE_SUPER_ADMIN') ? "ADMINISTRADOR" : "RECEPCIONISTA";
      }
    });
  }

  decodeJWT(token: string): any {
    return JSON.parse(atob(token.split('.')[1]));
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  logout() {
    this.isDropdownOpen = false; // Ocultar el menú desplegable al hacer clic en "Cerrar sesión"
    this.isLoading = true; // Mostrar el loader

    setTimeout(() => {
      this.loginService.logout();
      this.isLoading = false; // Ocultar el loader después de 1 segundo
      this.router.navigate(['/']).then(() => {
        this.isDropdownOpen = false; // Asegurarse de que el menú esté oculto después de redirigir
        this.clearStorage(); // Limpiar el almacenamiento local y de sesión
      });
    }, 1000);
    // Mantener el loader visible por 1 segundo
  }
  private clearStorage() {
    if (typeof window !== 'undefined' && window.localStorage) {
      localStorage.clear();
      sessionStorage.clear();
    }
  }
}
