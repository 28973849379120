import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { GeneralService } from '../services/general.service';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [RouterModule, NavbarComponent, SidebarComponent, CommonModule],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent {

  $hiddenSidebar?: Observable<boolean>;

  constructor(public generalService: GeneralService) {}

  ngOnInit() {
    this.$hiddenSidebar = this.generalService.getStatusActivitySidebar();
  }
}
