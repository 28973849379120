<div class="overlay" *ngIf="isLoading"></div>
<p-progressSpinner
  *ngIf="isLoading"
  ariaLabel="loading"
  class="loading-spinner"
></p-progressSpinner>
<header class="layout-topbar" *ngIf="exisToken">
  <div (click)="generalService.toggleActivitySidebar()" class="cursor-pointer">
    <i class="pi pi-bars" style="font-size: 2rem"></i>
  </div>
  <div class="layout-topbar-logo">
    <a href="/manager"><img src="/assets/images/logo-ribera.svg" alt="" /></a>
  </div>
  <div class="flex justify-content-end w-full items-center">
    <div class="user-info">
      <div class="user-details">
        <img
          class="layout-profile"
          alt=""
          src="/assets/images/avatar-profile.jpg"
        />
        <div class="flex align-items-center gap-2">
          <div class="flex flex-column">
            <div class="user-name poppins-semibold">{{activeRol}}</div>
            <div class="user-dni poppins-regular text-xs color-text-user flex justify-content-end"></div>
          </div>

          <div (click)="toggleDropdown()" class="user-dropdown-toggle">
            <i class="pi pi-chevron-down"></i>
          </div>
        </div>
        <div class="ml-5">
          <i class="pi pi-bell"></i>
        </div>
      </div>
      <div *ngIf="isDropdownOpen" class="user-dropdown-menu">
        <a (click)="logout()">Cerrar sesión</a>
      </div>
    </div>
  </div>
</header>
