<div class="bg-dashborad dashboard w-full flex">

  <app-sidebar></app-sidebar>

  <div class="wrapper flex-grow-1">

      <app-navbar></app-navbar>

      <div [class.large]="$hiddenSidebar | async" class="content">
        <router-outlet></router-outlet>
      </div>
  </div>
</div>
